/* purgecss start ignore */
.cs_html_form_container {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 13px;
}

.cs_html_form_container h2 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

p.cs_html_form_subtitle {
  font-size: 12px;
  margin: 0 0 12px;
  padding: 0;
}

.cs_html_form_row {
  display: block;
  margin-bottom: 7px;
}

.cs_html_form_row > p {
  font-size: 12px;
  font-weight: bold;
  padding: 0;
  margin: 10px 0 5px;
}

.cs_html_form input[type='text'] {
  width: 100%;
  padding: 7px;
  font-size: 13px;
  border: 1px solid #999;
  outline: none;
  background-color: #fff;
  color: #000;
}

.cs_html_form input.cs_html_form_input_error {
  border-color: #e80000;
}

div.cs_html_form_input_error {
  display: block;
  margin: 5px 0 0;
  padding: 0;
  font-size: 11px;
  color: #e80000;
  font-weight: bold;
}

.cs_html_form button {
  padding: 7px 20px;
  border: none;
  background: #777;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.cs_html_form button:hover {
  background: #555;
}

.cs_html_form_row ul {
  list-style: none;
  margin: 10px 0 10px 10px;
  padding: 0;
}

.cs_html_form_row li {
  margin: 0 0 5px;
}

.cs_html_form_row ul label {
  display: block;
}

.cs_html_form_row ul label input {
  vertical-align: bottom;
  top: -1px;
  position: relative;
  overflow: hidden;
  margin: 0;
}

.cs_html_form_result {
  display: none;
  color: #fff;
  background: #60c760;
  font-size: 13px;
  font-weight: bold;
  margin: 0 0 10px;
  padding: 5px 8px;
}

.cs_html_form_error {
  display: none;
  color: #fff;
  background: #e80000;
  font-size: 13px;
  font-weight: bold;
  margin: 0 0 10px;
  padding: 5px 8px;
}

.cs_html_form_terms {
  margin-top: 10px;
  font-size: 10px;
  color: #333;
}

.cs_html_form_terms a {
  color: #333;
  text-decoration: underline;
}

/* purgecss end ignore */
